import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = { class: "d-flex justify-content-end gap-2" }
const _hoisted_10 = ["onClick", "disabled"]
const _hoisted_11 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_add_merchant = _resolveComponent("add-merchant")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("user.merchants")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          type: "button",
          class: "btn btn-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#modal_add_user_merchant",
          disabled: _ctx.isDisabledAdd
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.t("user.addMerchant")), 1)
        ], 8, _hoisted_6)), [
          [_directive_auth, { auth: ['update'] }]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_MBDatatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        order: "desc",
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange,
        onSort: _ctx.onColumnSort
      }, {
        "cell-company_name": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.name), 1)
        ]),
        "cell-country": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.country), 1)
        ]),
        "cell-city": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.city), 1)
        ]),
        "cell-last_validation_date": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.formatUtcDate(item.last_validation_date)), 1)
        ]),
        "cell-lifecycle_status": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.lifecycle_status), 1)
        ]),
        "cell-vat_registration_number": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.vat_registration_number), 1)
        ]),
        "cell-actions": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-icon btn-light-danger",
              onClick: ($event: any) => (_ctx.removeItem(item)),
              disabled: item.deleteDisabled || _ctx.loading
            }, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
              ])
            ], 8, _hoisted_10)
          ])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ]),
    _createVNode(_component_add_merchant, { onUpdateList: _ctx.updateList }, null, 8, ["onUpdateList"])
  ]))
}