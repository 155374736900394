/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-30 13:16:41
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-30 13:16:55
 * @FilePath: /base-frontend/src/core/directive/function/common.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import moment from "moment";
import { CommonDateType } from "../type/common";

export const formatUtcDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
  return date ? moment(date).utcOffset(0).format(format) : "";
};
