
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ApiAuth } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { ElTable } from "element-plus";
import _ from "lodash";
import EmptySearch from "@/components/layout/EmptySearch.vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { NumberOrString } from "@/core/directive/type/common";

export default defineComponent({
  components: { EmptySearch },
  name: "add-user-merchant",
  emits: ["update-list"],
  setup(props, context) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addUserMerchantRef = ref<null | HTMLFormElement>(null);
    // const channelOptions = ref([]);
    const formData = ref({
      search: "",
      items: [] as any[],
      selectData: [] as any[],
    });

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const rules = ref({});

    const options = ref({
      //   fulfillment_type: getFulfillmentSourceRadio(t),
    });

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();

    const handleSelectionChange = (selection) => {
      formData.value.selectData = selection;
    };

    const getSubmitData = computed(() => {
      let arr: NumberOrString[] = [];
      formData.value.selectData.map((item) => {
        arr.push(item.id);
      });
      return arr;
    });

    const isDisabledSubmit = computed(() => {
      let flag = false;
      if (formData.value.selectData.length === 0) {
        flag = true;
      }
      return flag;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          addUserMerchant();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addUserMerchant = async () => {
      loading.value = true;
      const { data } = await ApiAuth.addUserMerchant({
        id: route.params.id,
        merchant_ids: getSubmitData.value,
      });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          context.emit("update-list");
          hideModal(addUserMerchantRef.value);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const getUserMerchantData = async () => {
      loading.value = true;
      const { data } = await ApiAuth.searchUserMerchant({
        value: formData.value.search,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value.items = data.data;
      }
    };

    const debounceUserMerchantSearch = _.debounce(getUserMerchantData, 1000);

    const searchUserMerchantItems = () => {
      debounceUserMerchantSearch();
    };

    const removeSearch = () => {
      formData.value.search = "";
      formData.value.items.splice(0, formData.value.items.length, ...[]);
    };

    const resetData = () => {
      formData.value.search = "";
      formData.value.items = [];
      formData.value.selectData = [];
    };

    const resetForm = () => {
      // formRef.value?.resetFields();
      resetData();
    };

    const handleDiscard = () => {
      hideModal(addUserMerchantRef.value);
    };

    onMounted(() => {
      modalHideListener(addUserMerchantRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      loading,
      formData,
      formRef,
      multipleTableRef,
      options,
      rules,
      addUserMerchantRef,
      isDisabledSubmit,
      removeSearch,
      handleSelectionChange,
      searchUserMerchantItems,
      resetForm,
      handleDiscard,
      submit,
    };
  },
});
